import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';

const Eucharistie = () => {
  return (
    <Container>
      <Paper style={{ padding: '20px', marginTop: '20px', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
        <Typography variant="h3" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center' }}>
          L'Eucharistie dans la Bible
        </Typography>

        <Box mb={3}>
          <Typography variant="h5" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', marginTop: '20px' }}>
            Introduction et Définitions
          </Typography>
          <Typography variant="body1" paragraph>
            Ce document a pour but d’introduire à la notion de sacrement et du réel sens biblique qu’à l’Eucharistie et la communion fraternelle, autour du sacrifice de notre Seigneur. Pour cela dans un premier temps nous définirons les termes utilisés dans ce document, dans un second temps nous introduirons la démonstration par le discours de la sainte cène et enfin pour finir à la lumière des Saintes Écritures nous expliquerons ce que le Christ entendais par ceci est mon corps ceci est mon sang.
          </Typography>
          <Typography variant="body1" paragraph>
            <b>Sacrement</b> : Acte symbolique (geste, parole), qui signifie une réalité invisible destinée à la sanctification des hommes. Dans le sacrement, c’est Dieu qui agit par l’intermédiaire de son ministre (prêtre ou diacre).
          </Typography>
          <Typography variant="body1" paragraph>
            <b>Eucharistie</b> : L’Eucharistie est le sacrement du vrai corps et du vrai sang de Jésus-Christ sous les apparences du pain et du vin pour la nourriture des fidèles et comme sacrifice de l’Église. Le terme « eucharist » provient du mot grec eucharistia, qui signifie action de grâces.
          </Typography>
        </Box>

        <Typography variant="h4" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center' }}>
          Le Discours de la Sainte Cène
        </Typography>

        <Box mb={3}>
          <Typography variant="body1" paragraph>
            Nous avons plusieurs mentions de cette nourriture spirituelle dites par notre Seigneur lui-même et on va les mentionner juste en dessous:
          </Typography>
          <Typography variant="body1" paragraph>
            «Pendant qu’ils mangeaient, Jésus prit du pain; et, après avoir rendu grâces, il le rompit, et le leur donna, en disant: Prenez, ceci est mon corps. Il prit ensuite une coupe; et, après avoir rendu grâces, il la leur donna, et ils en burent tous. Et il leur dit: Ceci est mon sang, le sang de l’alliance, qui est répandu pour plusieurs. Je vous le dis en vérité, je ne boirai plus jamais du fruit de la vigne, jusqu’au jour où je le boirai nouveau dans le royaume de Dieu.» <br />
            <i>- Marc 14:22-25 LSG</i>
          </Typography>
          <Typography variant="body1" paragraph>
            «Nos pères ont mangé la manne dans le désert, ainsi qu'il est écrit: Il leur a donné à manger le pain du ciel." Jésus leur répondit: "En vérité, en vérité, je vous le dis, Moïse ne vous a pas donné le pain du ciel; c'est mon Père qui vous donne le vrai pain du ciel. Car le pain de Dieu, c'est le pain qui descend du ciel et qui donne la vie au monde." Ils lui dirent donc: "Seigneur, donnez-nous toujours de ce pain." Jésus leur répondit: "Je suis le pain de vie: celui qui vient à moi n'aura jamais faim, et celui qui croit en moi n'aura jamais soif.» <br />
            <i>- Jean 6:31-35</i>
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography variant="body1" paragraph>
            «Pendant le repas, Jésus prit le pain; et, ayant prononcé une bénédiction, il le rompit et le donna à ses disciples, en disant: "Prenez et mangez, ceci est mon corps." Il prit ensuite la coupe, et, ayant rendu grâces, il la leur donna en disant: "Buvez-en tous: car ceci est mon sang, le sang de la nouvelle alliance, répandu pour la multitude en rémission des péchés.» <br />
            <i>- Matthieu 26:26-28</i>
          </Typography>
          <Typography variant="body1" paragraph>
            «Puis il prit du pain, et ayant rendu grâces, il le rompit et le leur donna, en disant: "Ceci est mon corps, qui est donné pour vous: faites ceci en mémoire de moi." Il fit de même pour la coupe, après le souper, disant: "Cette coupe est la nouvelle alliance en mon sang, lequel est versé pour vous.» <br />
            <i>- Luc 22:19-20</i>
          </Typography>
          <Typography variant="body1" paragraph>
            La première question qu’on pourrait se poser c’est pourquoi Jésus Christ se donne lui-même en nourriture à nous?
          </Typography>
          <Typography variant="body1" paragraph>
            On peut comprendre que Jésus se donne à nous dans l'Eucharistie comme nourriture spirituelle parce qu'il nous aime, autrefois Dieu donnait ce pain qui venait du ciel à son peuple élu. En mangeant le Corps et en buvant le Sang du Christ dans l'Eucharistie, nous sommes unis à la personne du Christ par son humanité. "Quiconque mange ma chair et boit mon sang reste en moi et moi en lui" (Jean 6:56). En étant unis à l'humanité du Christ, nous sommes en même temps unis à sa divinité. Nos natures mortelles et corruptibles sont transformées en étant jointes à la source de la vie.
          </Typography>
        </Box>

        <Typography variant="h4" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center' }}>
          L'Eucharistie est-elle un symbole?
        </Typography>

        <Box mb={3}>
          <Typography variant="body1" paragraph>
            Le pain et le vin transformés sont vraiment le Corps et le Sang du Christ et ne sont pas simplement des symboles. Quand le Christ a dit « C'est mon corps » et « C'est mon sang », le pain et le vin deviennent vraiment son corps et son sang. Bien que le pain et le vin semblent identiques à nos facultés humaines, ils sont en fait le vrai corps et le vrai sang de Jésus.
          </Typography>
          <Typography variant="body1" paragraph>
            Comprenez que même les juifs eux-mêmes ne croyaient pas que c’était vraiment le corps et le sang du Christ: «Là-dessus, les Juifs disputaient entre eux, disant: "Comment cet homme peut-il donner sa chair à manger?"» <br />
            <i>- Jean 6:52</i>
          </Typography>
          <Typography variant="body1" paragraph>
            À cela «Jésus leur dit: "En vérité, en vérité, je vous le dis, si vous ne mangez la chair du Fils de l'homme, et ne buvez son sang, vous n'avez point la vie en vous-mêmes.» <br />
            <i>- Jean 6:53</i>
          </Typography>
        </Box>

        <Typography variant="h4" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center' }}>
          Confirmation par Saint Paul
        </Typography>

        <Box mb={3}>
          <Typography variant="body1" paragraph>
            Et saint Paul nous confirme cela dans 1 Corinthiens 11:26-29 «Car toutes les fois que vous mangez ce pain et que vous buvez ce calice, vous annoncez la mort du Seigneur, jusqu'à ce qu'il vienne. C'est pourquoi celui qui mangera le pain ou boira le calice du Seigneur indignement, sera coupable envers le corps et le sang du Seigneur. Que chacun donc s'éprouve soi-même, et qu'ainsi il mange de ce pain et boive de ce calice; car celui qui mange et boit [indignement], sans discerner le corps du Seigneur, mange et boit son propre jugement.»
          </Typography>
          <Typography variant="body1" paragraph>
            On voit clairement que saint Paul lui-même affirme que c’est le corps et le sang de notre seigneur à ce moment-là, sinon il nous parlerait pas de discerner le corps.
          </Typography>
        </Box>

        <Typography variant="h4" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center' }}>
          Où retrouver cette Eucharistie?
        </Typography>

        <Box mb={3}>
          <Typography variant="body1" paragraph>
            On répondra que l'eucharistie se retrouve dans la messe qui commence par la préparation des dons et de l'autel. Alors que les ministres préparent l'autel, les représentants du peuple apportent le pain et le vin qui deviendront le Corps et le Sang du Christ. Le célébrant bénit et loue Dieu pour ces dons et les place sur l'autel, le lieu du sacrifice eucharistique.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default Eucharistie;
