import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActionArea, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import EucharistieIcon from './eucharistie.svg';  // Import the SVG file

const Home = () => {
  return (
    <Container className="container-root">
      <Typography 
        variant="h4" 
        gutterBottom 
        style={{ 
          textAlign: 'center', 
          fontFamily: 'Gabarito, Open Sans, sans-serif',
          marginTop: '20px' 
        }}>
        Les Controverses
      </Typography>
      <Divider variant="middle" style={{ margin: '20px 0' }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardActionArea component={Link} to="/parler-en-langues">
              <CardContent>
                <Typography 
                  variant="h5" 
                  style={{ 
                    fontFamily: 'Gabarito, Open Sans, sans-serif' 
                  }}>
                  🗣️ Parler en Langues
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardActionArea component={Link} to="/eucharistie">
              <CardContent>
                <Typography 
                  variant="h5" 
                  style={{ 
                    fontFamily: 'Gabarito, Open Sans, sans-serif',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <img src={EucharistieIcon} alt="Eucharistie Icon" style={{ marginRight: '10px', height: '24px' }} />
                  Eucharistie
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardActionArea component={Link} to="/sucession-apostolique">
              <CardContent>
                <Typography 
                  variant="h5" 
                  style={{ 
                    fontFamily: 'Gabarito, Open Sans, sans-serif' 
                  }}>
                  ✝️ Succession Apostolique
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardActionArea component={Link} to="/autorite-apostolique">
              <CardContent>
                <Typography 
                  variant="h5" 
                  style={{ 
                    fontFamily: 'Gabarito, Open Sans, sans-serif' 
                  }}>
                  📜 Autorité Apostolique
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
