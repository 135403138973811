import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';

const AutoriteApostolique = () => {
  return (
    <Container>
      <Paper style={{ padding: '20px', marginTop: '20px', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
        <Typography variant="h3" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center' }}>
          L'Autorité Apostolique et la Prophétie Authentique dans l'Église
        </Typography>

        <Box mb={3}>
          <Typography variant="body1" paragraph>
            Les Apôtres originaux furent spécifiquement choisis par Jésus-Christ Lui-même. Les Douze Apôtres avaient un rôle unique et fondateur dans l'Église. Le titre d'« apôtre » ne peut donc pas être autoproclamé ou attribué par d'autres que par le Christ Lui-même. En dehors des Douze (Matthias inclus), seul Paul n'a pas côtoyé directement Jésus durant son ministère terrestre. Après sa révélation de Jésus sur le chemin de Damas, Paul commença son ministère apostolique. Malgré cela, il alla voir les « colonnes » de l'Église pour confirmer son enseignement :
          </Typography>
          <Typography variant="body1" paragraph>
            « Ayant reconnu la grâce qui m'avait été accordée, Jacques, Céphas et Jean, qui sont regardés comme des colonnes, me donnèrent à moi et à Barnabas la main d'association, afin que nous allassions, nous vers les païens, et eux vers les circoncis. » <br />
            <i>- Galates 2, 7-9</i>
          </Typography>
          <Typography variant="body1" paragraph>
            Cet exemple illustre l'importance de la soumission à l'autorité ecclésiale pour éviter les faux prophètes, comme Paul le dit : « Ces hommes-là sont de faux apôtres, des ouvriers trompeurs, déguisés en apôtres du Christ. » <br />
            <i>- 2 Corinthiens 11, 13</i>
          </Typography>
        </Box>

        <Typography variant="h4" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center' }}>
          Les Signes Distinctifs des Apôtres
        </Typography>

        <Box mb={3}>
          <Typography variant="body1" paragraph>
            En examinant les Écritures, nous voyons que les Apôtres avaient des signes distinctifs : ils étaient témoins de la résurrection du Christ (Actes 1, 22), avaient reçu une commission directe de Jésus (Matthieu 28, 19-20) et manifestaient des signes et des miracles (2 Corinthiens 12, 12). Ces critères excluent toute possibilité d'auto-proclamation apostolique.
          </Typography>
          <Typography variant="body1" paragraph>
            Quant aux prophètes modernes, les prétendues prophéties qui se concentrent sur la richesse, le succès matériel ou la réussite personnelle divergent souvent des prophéties bibliques en termes de contenu et de but. Les prophéties bibliques visent à édifier la foi, à encourager la communauté à vivre selon la volonté de Dieu et à réconforter dans les moments de difficulté.
          </Typography>
          <Typography variant="body1" paragraph>
            Par exemple, le prophète Agabus est reconnu pour ses prophéties :
          </Typography>
          <Typography variant="body1" paragraph>
            "En ce temps-là, des prophètes descendirent de Jérusalem à Antioche. L'un d'eux, nommé Agabus, se leva et annonça par l'Esprit qu'il y aurait une grande famine sur toute la terre. Elle arriva, en effet, sous Claude." <br />
            <i>- Actes 11, 27-28</i>
          </Typography>
          <Typography variant="body1" paragraph>
            "Nous étions là depuis plusieurs jours, quand un prophète, nommé Agabus, descendit de Judée, et vint nous trouver. Il prit la ceinture de Paul, se lia les pieds et les mains, et dit : Voici ce que déclare le Saint-Esprit : L'homme à qui appartient cette ceinture, les Juifs le lieront de cette manière à Jérusalem et le livreront entre les mains des païens." <br />
            <i>- Actes 21, 10-11</i>
          </Typography>
        </Box>

        <Typography variant="h4" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center' }}>
          La Validation des Prophéties
        </Typography>

        <Box mb={3}>
          <Typography variant="body1" paragraph>
            Agabus était un prophète accepté et reconnu par les piliers de l'Église, illustrant l'importance de l'autorité ecclésiale dans la validation des prophéties.
          </Typography>
          <Typography variant="body1" paragraph>
            La prophétie, selon saint Paul, est un don spirituel important qui sert à l'édification, à l'exhortation et à la consolation de l'Église :
          </Typography>
          <Typography variant="body1" paragraph>
            "Celui qui prophétise, au contraire, parle aux hommes, les édifie, les exhorte, les console." <br />
            <i>- 1 Corinthiens 14, 3</i>
          </Typography>
          <Typography variant="body1" paragraph>
            Paul insiste sur l'ordre et la décence dans l'exercice de la prophétie dans l'Église, soulignant que la prophétie doit être évaluée par les autres membres de la communauté pour vérifier son authenticité et sa conformité aux enseignements de l'Évangile :
          </Typography>
          <Typography variant="body1" paragraph>
            "Que deux ou trois prophètes parlent, et que les autres jugent." <br />
            <i>- 1 Corinthiens 14, 29</i>
          </Typography>
          <Typography variant="body1" paragraph>
            Les prophéties authentiques doivent donc être conformes à l'enseignement de l'Évangile, à l'autorité de l'Église, et soumises au discernement communautaire, afin de prévenir les abus et de garantir qu'elles servent véritablement à l'édification de l'Église. Par exemple, serait-il pour l'édification de l'Église si un homme prophétisait une villa pour son assemblée ?
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default AutoriteApostolique;
