import React, { useState, useEffect, useCallback } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, Box, CssBaseline, Paper } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import logo from '../logo.png';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const controlHeader = useCallback(() => {
    if (window.scrollY > lastScrollY && window.scrollY > 100) {
      // Scrolling down and more than 100px from the top
      setShowHeader(false);
    } else {
      // Scrolling up
      setShowHeader(true);
    }
    setLastScrollY(window.scrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', controlHeader);
    return () => {
      window.removeEventListener('scroll', controlHeader);
    };
  }, [controlHeader]);

  const drawer = (
    <Box onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle} sx={{ width: 250 }}>
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Accueil" />
        </ListItem>
        <ListItem button component={Link} to="/parler-en-langues">
          <ListItemText primary="Parler en Langues" />
        </ListItem>
        <ListItem button component={Link} to="/eucharistie">
          <ListItemText primary="Eucharistie" />
        </ListItem>
        <ListItem button component={Link} to="/sucession-apostolique">
          <ListItemText primary="Succession Apostolique" />
        </ListItem>
        <ListItem button component={Link} to="/autorite-apostolique">
          <ListItemText primary="Autorité Apostolique" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" sx={{ top: showHeader ? 0 : '-100px', left: 0, right: 0, transition: 'top 0.3s' }}>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
              <img src={logo} alt="Les Controverses" style={{ height: 50, marginRight: 10 }} />
              <Typography variant="h6" noWrap component="div">
                Les Controverses
              </Typography>
            </Link>
          </Box>
          <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* Dummy Toolbar to push the content below the AppBar */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
      <Paper sx={{ mt: 2, boxShadow: 'none', marginTop: 0 }}>
        {/* Your content goes here */}
      </Paper>
    </>
  );
};

export default Header;
