import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/accueil';
import ParlerEnLangues from './pages/parlerenlangues';
import Eucharistie from './pages/eucharistie';
import SucessionApostolique from './pages/successionapostolique';
import AutoriteApostolique from './pages/autoriteapostolique';

const App = () => {
  return (
    <Router>
      <Header />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/parler-en-langues" element={<ParlerEnLangues />} />
          <Route path="/eucharistie" element={<Eucharistie />} />
          <Route path="/sucession-apostolique" element={<SucessionApostolique />} />
          <Route path="/autorite-apostolique" element={<AutoriteApostolique />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
