import React from 'react';
import { Container, Typography, Paper, Box, Divider } from '@mui/material';

const SucessionApostolique = () => {
  return (
    <Container>
      <Paper style={{ padding: '20px', marginTop: '20px', marginBottom: '20px', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
        <Box style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '8px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
          <Typography variant="h3" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center', textDecoration: 'underline' }}>
            L'Imposition des Mains
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0' }} />

        <Box mb={3}>
          <Typography variant="h6" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', marginTop: '20px' }}>
            Introduction
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            L’imposition des mains est une pratique qui trouve ses origines dans l’Ancien et le Nouveau Testament, on l’observe dans différents contextes et dans différents objectifs. Il existe 3 grands types d’imposition des mains :
          </Typography>
          <ul>
            <li>
              <Typography variant="body1" paragraph style={{ marginBottom: '10px' }}>
                Celle qui a pour but la <b>réception de l’Esprit Saint</b> :
                « Ceux-ci, arrivés chez les Samaritains, prièrent pour eux, afin qu'ils reçoivent le Saint-Esprit. Car il n'était encore descendu sur aucun d'eux; ils avaient seulement été baptisés au nom du Seigneur Jésus. Alors Pierre et Jean leur imposèrent les mains, et ils reçurent le Saint-Esprit. » <br />
                <b>- Actes 8, 15-17</b>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" paragraph style={{ marginBottom: '10px' }}>
                Celle qui a pour but une <b>action précise</b> comme la guérison,
                « […] ils saisiront des serpents; s'ils boivent quelque breuvage mortel, il ne leur fera point de mal; ils imposeront les mains aux malades, et les malades, seront guéris. » <br />
                <b>- Marc 16, 18</b>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" paragraph style={{ marginBottom: '10px' }}>
                Enfin, celle qui nous intéresse, qui a pour but la <b>succession/transmission du ministère</b>, dont on entame ici le développement.
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            On peut lire dans <b>Nombres 27, 18-23</b> :
            « L'Eternel dit à Moïse: Prends Josué, fils de Nun, homme en qui réside l'esprit; et tu poseras ta main sur lui. Tu le placeras devant le sacrificateur Eléazar et devant toute l'assemblée, et tu lui donneras des ordres sous leurs yeux. Tu le rendras participant de ta dignité, afin que toute l'assemblée des enfants d'Israël l'écoute. Il se présentera devant le sacrificateur Eléazar, qui consultera pour lui le jugement de l'Urim devant l'Eternel; et il se présentera devant le sacrificateur Eléazar et devant toute l'assemblée, sortiront sur l'ordre d'Eléazar et entreront sur son ordre. Moïse fit ce que l'Eternel lui avait ordonné. Il prit Josué, et il le plaça devant le sacrificateur Eléazar et devant toute l'assemblée. Il posa ses mains sur lui, et lui donna des ordres, comme l'Eternel l'avait dit par Moïse. »
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            On constate ici que l’Esprit Saint “réside” en Josué (il lui a été donné par l'imposition des mains de Moïse dans <b>Deutéronome 34:9</b>), mais, malgré le fait que l'Esprit réside en lui, c’est l'imposition des mains de Moïse dans le but de transmettre son ministère qui lui donne son autorité sur “l'assemblée des enfants d'Israël”.
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            Lisons également <b>Actes 6, 2-6</b> :
            « Les douze convoquèrent la multitude des disciples, et dirent: Il n'est pas convenable que nous laissions la parole de Dieu pour servir aux tables. C'est pourquoi, frères, choisissez parmi vous sept hommes, de qui l'on rende un bon témoignage, qui soient pleins d'Esprit Saint et de sagesse, et que nous chargerons de cet emploi. Et nous, nous continuerons à nous appliquer à la prière et au ministère de la parole. Cette proposition plut à toute l'assemblée. Ils élurent Étienne, homme plein de foi et d'Esprit Saint, Philippe, Prochore, Nicanor, Timon, Parménas, et Nicolas, prosélyte d'Antioche. Ils les présentèrent aux apôtres, qui, après avoir prié, leur imposèrent les mains. »
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0' }} />

        <Box style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '8px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
          <Typography variant="h4" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center', textDecoration: 'underline' }}>
            L'Imposition des Mains pour le Ministère
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0' }} />

        <Box mb={3}>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            On constate ici également qu’être <b>“plein de l’Esprit Saint”</b> est présenté comme une nécessité pour recevoir cette imposition des mains qui leur transmet ce ministère. Saint Paul mentionne cette même imposition dans <b>1 Timothée 4, 14</b> : « Ne néglige pas le don qui est en toi, et qui t'a été donné par prophétie avec l'imposition des mains de l'assemblée des anciens. » ou encore dans <b>1 Timothée 5, 22</b> : « N'impose les mains à personne avec précipitation, ne participe pas aux péchés d'autrui; toi-même, conserve-toi pur. »
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            Saint Paul lui-même fut baptisé et reçut l'Esprit Saint par imposition des mains dans <b>Actes 9, 17-18</b>. C'est seulement plus tard, qu'on lui imposa les mains à nouveau parce qu'il avait été appelé par l'Esprit Saint à ce ministère, en effet, dans <b>Actes 13, 1-3</b> nous lisons :
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            « Il y avait dans l'Eglise d'Antioche des prophètes et des docteurs: Barnabas, Siméon appelé Niger, Lucius de Cyrène, Manahem, qui avait été élevé avec Hérode le tétrarque, et Saul. Pendant qu'ils servaient le Seigneur dans leur ministère et qu'ils jeûnaient, le Saint-Esprit dit: Mettez-moi à part Barnabas et Saul pour l'oeuvre à laquelle je les ai appelés. Alors, après avoir jeûné et prié, ils leur imposèrent les mains, et les laissèrent partir. »
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            D'après ces versets, être appelé par l'Esprit Saint à ce ministère ne suffit pas, il faut recevoir cette imposition des mains de personnes qui ont elle-même été validement établies. Cette succession est bien exprimée par Saint Paul dans <b>2 Timothée 2, 2</b> : « Et ce que tu as entendu de moi en présence de beaucoup de témoins, confie-le à des hommes fidèles, qui soient capables de l'enseigner aussi à d'autres. » On voit qu’il est nécessaire aux acteurs de ce ministère de prêcher le même enseignement que celui qui leur a été transmis. Prenons l'exemple de Saint Paul qui cherche à garder l'enseignement de l'Église, il nous indique dans <b>Galates 2, 1-3</b> avoir exposé l'Évangile qu'il prêche aux autorités de l'Église :
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            « Quatorze ans après, je montai de nouveau à Jérusalem avec Barnabas, ayant aussi pris Tite avec moi; et ce fut d'après une révélation que j'y montai. Je leur exposai l'Évangile que je prêche parmi les païens; je l'exposai en particulier à ceux qui sont les plus considérés, afin de ne pas courir ou avoir couru en vain. »
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0' }} />

        <Box style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '8px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
          <Typography variant="h4" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center', textDecoration: 'underline' }}>
            La Transmission du Ministère
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0' }} />

        <Box mb={3}>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            L'Église établit ainsi ses pasteurs aux endroits où elle prêche l'Évangile,
            <b>Actes 14, 21-23</b> : « Quand ils eurent évangélisé cette ville et fait un certain nombre de disciples, ils retournèrent à Lystre, à Icone et à Antioche, fortifiant l'esprit des disciples, les exhortant à persévérer dans la foi, et disant que c'est par beaucoup de tribulations qu'il nous faut entrer dans le royaume de Dieu. Ils firent nommer des anciens dans chaque Église, et, après avoir prié et jeûné, ils les recommandèrent au Seigneur, en qui ils avaient cru. »
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            <b>Tite 1:5</b> : « Je t'ai laissé en Crète, afin que tu mettes en ordre ce qui reste à régler, et que, selon mes instructions, tu établisses des anciens dans chaque ville »
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            <b>Deutéronome 16:18</b> : « Tu établiras des juges et des magistrats dans toutes les villes que l'Éternel, ton Dieu, te donne, selon tes tribus; et ils jugeront le peuple avec justice. »
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            Les évêques, en effet, sont ceux qui sont appelés à paître l'Église, à être pasteurs, <b>Actes 20, 28</b> : « Prenez donc garde à vous-mêmes, et à tout le troupeau sur lequel le Saint-Esprit vous a établis évêques, pour paître l'Église du Seigneur, qu'il s'est acquise par son propre sang. »
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            Pour finir, soulignons comment la Bible nous indique qu’il est primordial d’avoir cette approbation de l'Église, cette ordination, pour être pasteur, <b>Actes 15, 24</b> : « Ayant appris que quelques hommes partis de chez nous, et auxquels nous n'avions donné aucun ordre, vous ont troublés par leurs discours et ont ébranlé vos âmes »
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            <b>Romains 10:15</b> : « Et comment y aura-t-il des prédicateurs, s'ils ne sont pas envoyés? selon qu'il est écrit: Qu'ils sont beaux Les pieds de ceux qui annoncent la paix, De ceux qui annoncent de bonnes nouvelles! »
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0' }} />

        <Box style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '8px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
          <Typography variant="h4" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center', textDecoration: 'underline' }}>
            Conclusion
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0' }} />

        <Box mb={3}>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            En conclusion, un pasteur est établi de manière valide et rempli correctement son rôle si :
          </Typography>
          <ul>
            <li>
              <Typography variant="body1" style={{ marginBottom: '10px' }}>
                <b>Il a reçu l'imposition des mains d'évêques eux-mêmes validement établis</b>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={{ marginBottom: '10px' }}>
                <b>Il a reçu le Saint-Esprit</b>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={{ marginBottom: '10px' }}>
                <b>Il “se conserve pur” et veille à transmettre l’enseignement de l’Eglise</b>
              </Typography>
            </li>
          </ul>
        </Box>
      </Paper>
    </Container>
  );
};

export default SucessionApostolique;
