import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';

const ParlerEnLangues = () => {
  return (
    <Container>
      <Paper style={{ padding: '20px', marginTop: '20px', marginBottom: '20px', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
        <Typography variant="h3" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center' }}>
          Le Don des Langues
        </Typography>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', marginTop: '20px' }}>
            Introduction
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            Le don des langues, mentionné dans le Nouveau Testament, est une manifestation de l'Esprit Saint qui se présente sous deux formes : la xénolalie, parler une langue inconnue, et la glossolalie, prononcer des paroles mystérieuses. Bien qu'il soit considéré comme un don mineur, son but est l'édification de la communauté chrétienne, nécessitant clarté et interprétation pour être véritablement bénéfique.
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            « À chacun la manifestation de l'Esprit est donnée pour l'utilité commune. En effet, à l'un est donnée par l'Esprit une parole de sagesse, à l'autre une parole de connaissance, selon le même Esprit ; à un autre, la foi, par le même Esprit ; à un autre, des dons de guérisons, par ce seul et même Esprit ; à un autre, la puissance d'opérer des miracles ; à un autre, la prophétie ; à un autre, le discernement des esprits ; à un autre la diversité des langues ; à un autre le don de les interpréter. Mais c'est le seul et même Esprit qui produit tous ces dons, les distribuant à chacun en particulier, comme il lui plaît. » <br />
            <i>- 1 Corinthiens 12, 7-11</i>
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            Par « don des langues », le Nouveau Testament désigne deux charismes différents :
          </Typography>
          <ul>
            <li>
              <Typography variant="body1" style={{ marginBottom: '10px' }}>
                soit le fait de parler une ou plusieurs langues étrangères sans les avoir apprises auparavant, comme le firent les Apôtres le jour de la Pentecôte (cf. Actes 2). C'est ce qu'on appelle la <b>xénolalie</b>.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={{ marginBottom: '10px' }}>
                soit le fait de tenir, sous l'emprise de l'Esprit-Saint, des propos mystérieux qui demandent à être interprétés. C'est ce qu'on appelle la <b>glossolalie</b>.
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            Le parler en langues, plus petit des charismes selon saint Paul, est une louange rendue au Seigneur au-delà des mots. Il apparaît de l'extérieur comme une sorte de babillage où, dans le prolongement de la louange ordinaire, on laisse l'Esprit-Saint saisir nos lèvres pour pousser des « soupirs inexprimables » (cf. Romains 8, 26). On peut reconnaître l'action de l'Esprit grâce aux fruits qui s'ensuivent ; l'harmonie et le caractère paisible peuvent être un indice (l'action de l'Esprit-Saint n'est pas hystérie, pensons à l'apparition du Seigneur au prophète Élie au moment de la brise légère à l'inverse des hurlements et agitation des possédés par les esprits impurs).
          </Typography>
        </Box>

        <Typography variant="h4" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center' }}>
          Le “parler en langues” doit-il être centré sur l'édification personnelle ou sur l'édification de la communauté ?
        </Typography>
        
        <Box mb={3}>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            Paul encourage à rechercher les dons qui édifient l'Église. Si le parler en langues est utilisé principalement pour l'édification personnelle, cela va à l'encontre de l'enseignement biblique qui privilégie l'édification collective. De plus, les manifestations de parler en langues doivent être compréhensibles pour être bénéfiques à la communauté.
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            Selon les Écritures, le parler en langues doit principalement servir l'édification de la communauté chrétienne, pas seulement l'édification personnelle.
          </Typography>
          <ul>
            <li>
              <Typography variant="body1" style={{ marginBottom: '10px' }}>
                <b>« De même vous aussi, puisque vous aspirez aux dons spirituels, que ce soit pour l'édification de l'Église que vous cherchiez à en avoir abondamment. »</b> <br />
                <i>- 1 Corinthiens 14, 12</i>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={{ marginBottom: '10px' }}>
                <b>« À chacun la manifestation de l'Esprit est donnée pour l'utilité commune. »</b> <br />
                <i>- 1 Corinthiens 12, 7</i>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={{ marginBottom: '10px' }}>
                <b>« Les dons doivent être clairement compréhensibles pour profiter à l'assemblée. »</b> <br />
                <i>- 1 Corinthiens 14, 6</i>
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            Si les manifestations de parler en langues sont incompréhensibles et non interprétées, elles ne respectent pas les principes bibliques de clarté et d'ordre, réduisant leur utilité pour l'assemblée.
          </Typography>
        </Box>

        <Typography variant="h4" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center' }}>
          Les manifestations de parler en langues devraient-elles être compréhensibles pour les autres membres de l'Église ?
        </Typography>
        
        <Box mb={3}>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            <b>« Quelque nombreuses que puissent être dans le monde les diverses langues, il n'en est aucune qui consiste en sons inintelligibles. Si j'ignore la valeur du son, je serai un barbare pour celui qui parle, et celui qui parle sera un barbare pour moi. »</b> <br />
            <i>- 1 Corinthiens 14:10-11</i>
          </Typography>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            Les pratiques incompréhensibles sont qualifiées de folies.
          </Typography>
          <ul>
            <li>
              <Typography variant="body1" style={{ marginBottom: '10px' }}>
                <b>« Si l'on parle en langue, que ce soient chaque fois deux ou trois au plus, chacun à son tour, et qu'il y ait un interprète ; s'il n'y a point d'interprète, qu'on se taise dans l'assemblée et qu'on se parle à soi-même et à Dieu. »</b> <br />
                <i>- 1 Corinthiens 14:27-28</i>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={{ marginBottom: '10px' }}>
                <b>« C'est pourquoi, celui qui parle en langue prie pour obtenir le don d'interpréter. »</b> <br />
                <i>- 1 Corinthiens 14:13</i>
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            Il est crucial d'avoir une interprétation pour que le parler en langues soit utile.
          </Typography>
        </Box>

        <Typography variant="h4" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: '#3f51b5', textAlign: 'center' }}>
          Les manifestations désordonnées de “parler en langues” sont-elles conformes aux principes bibliques d'ordre et de décence ?
        </Typography>
        
        <Box mb={3}>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            <b>« Si j'ignore la valeur du son, je serai un barbare pour celui qui parle, et celui qui parle sera un barbare pour moi. »</b> <br />
            <i>- 1 Corinthiens 14:11</i>
          </Typography>
          <ul>
            <li>
              <Typography variant="body1" style={{ marginBottom: '10px' }}>
                <b>« De même vous, si vous ne faites pas entendre avec la langue une parole distincte, comment saura-t-on ce que vous dites ? Vous parlerez en l'air. »</b> <br />
                <i>- 1 Corinthiens 14:9</i>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={{ marginBottom: '10px' }}>
                <b>« Si donc, l'Église entière se trouvant réunie en assemblée, tous parlent en langues, et qu'il survienne des hommes non initiés ou des infidèles, ne diront-ils pas que vous êtes fous ? »</b> <br />
                <i>- 1 Corinthiens 14:23</i>
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" paragraph style={{ marginBottom: '20px' }}>
            Les manifestations désordonnée qui ne sont pas claires ou interprétées ne respectent pas les principes bibliques d'ordre et de compréhension, ce qui les rend moins bénéfiques pour l'assemblée.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default ParlerEnLangues;
